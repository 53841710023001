import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://ontarioplastic.ca/">
        www.ontarioplastic.ca
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

export default function App() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>

          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
              Ontario Plastic
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              With more than 20 years in business, Ontario Plastic is your one-stop solution for all your plastic reprocessing needs.
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button variant="contained" color="primary">
                    Call (416)452-1358
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            <Grid item xs={12} >
              <Card className={classes.card}>
                <CardMedia
                  className={classes.cardMedia}
                  image="https://source.unsplash.com/random"
                  title="Image title"
                />
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h3" component="h1">
                    About Us
                    </Typography>
                  <Typography>
                    We at Ontario Plastic take pride in our Safety records. Zero lost time injuries in the last 20 years speak for itself. We strive for timely delivery of services in the safest and efficient manner. Our Customers rely on us to manage their rejection rates. We provide value added services such as Grinding, Compounding, and logistics. We are strategically located in the GTA to serve our Customers with same-day delivery of their material. With qualified staff and spare capacity, you can rest assured that your material will be handled and processed in the most efficient manner.
                    </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} >
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h3" component="h1">
                    Services Offered
                    </Typography>
                  <Typography gutterBottom variant="h4" component="h2">
                    Below are some of the services that we offer:
                    </Typography>
                  <Typography gutterBottom variant="h5" component="h5">
                    Toll Grinding
                    </Typography>
                  <Typography gutterBottom variant="h5" component="h5">
                    Toll Shredding

                    </Typography>
                  <Typography gutterBottom variant="h5" component="h5">
                    Toll Re pelletizing and compounding
                    </Typography>
                  <Typography gutterBottom variant="h5" component="h5">
                    We buy and sell any form of plastic material (HDPE, PP, Nylon, HIPS, BOPP).
                    </Typography>
                  <Typography gutterBottom variant="h5" component="h5">
                    With a production capacity of over 1200 Lbs/Hr. we can turn around truckloads of material in a matter of days.
                    </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <footer className={classes.footer}>
        <Typography variant="h6" align="center" gutterBottom>
          Contact us
        </Typography>
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          7575 Kimbel Street <br />
          Unit 12-13<br />
          Mississauga<br />
          Ontario L5S1C8 <br />
          Call (416)452-1358 |   ontarioplastic at hotmail.com
        </Typography>
        <Copyright />
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}